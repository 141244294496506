<template>
    <div class="qingwu">
        <div id="editor"></div>
        <textarea v-model="content" style="display: none"></textarea>
    </div>
</template>

<script>
    import wangeditor from "wangeditor";
    import OSS from "ali-oss";

    const btnmenu = [
        'head',//标题
        'bold',//粗体
        // 'fontSize',//字号
        // 'fontName',//字体
        // 'italic',//斜体
        // 'undeline',//下划线
        // 'strikeThrough',//删除线
        'foreColor',//文字颜色
        'backColor',//背景颜色
        // 'link',//插入链接
        'list',//列表
        'justify',//对齐方式
        // 'quote',//引用
        // 'emoticon',//表情
        'image',//插入图片
        'table',//表格
        // 'video',//插入视频
        // 'code',//插入代码
        // 'undo', //撤销
        // 'redo', // 重复
    ]
    export default {
        components: {},
        props: {
            contents: {
                type: String,
                default: "",
            },
        },
        data() {
            return {
                token: "",
                content: "",

                client: null,
                filename: '',
            };
        },
        watch: {
            contents: {
                handler() {
                    this.content = this.contents;
                    this.token = localStorage.getItem("token");
                    this.$nextTick(() => {
                        this.create_editor();
                    });
                },
                immediate: true,
            },
        },

        created() {
            this.client = new OSS(this.$config.aliOss)
        },
        methods: {
            async ossUpload(files) {
                let _this = this
                let list = [];
                for (const file of files) {
                    let filename = file.name;
                    filename = filename.split('.')[0] + '_' + new Date().getTime() + '.' + filename.split('.')[1]
                    let path = "appxcx/upload/img/" + filename;
                    try {
                        list.push(await _this.client.multipartUpload(path, file));
                    } catch (e) {
                        console.log(e)
                    }
                }
                return list;
            },
            create_editor() {
                const editor = new wangeditor("#editor");
                editor.config.zIndex = 100;
                // 自定义菜单配置
                editor.config.menus = btnmenu;
                editor.config.showLinkImg = false;
                editor.config.uploadFileName = "file";
                editor.config.uploadImgMaxSize = 10 * 1024 * 1024; // 将图片大小限制为 10M
                // editor.config.pasteFilterStyle  = false;
                // eslint-disable-next-line no-unused-vars
                editor.config.customUploadImg = async (files, insert) => {
                    for (const res of files) {
                        if(res.size > (10 * 1024 * 1024)){
                            console.log(res.name,res.size,res.type,'将图片大小限制为 10M');
                            return false;
                        }
                    }
                    const result = await this.ossUpload(files)
                    for (const res of result) {
                        if (res) {
                            var img = res.res.requestUrls[0].split("?uploadId");
                            console.log(img[0]+"?x-oss-process=style/wangeditor", "insert");
                            insert(img[0])
                        }
                    }
                    this.$message.success('上传成功')
                };
                editor.config.uploadImgMaxLength = 9;
                editor.config.uploadImgServer = this.$api.upload; // 上传图片到服务器
                editor.config.uploadImgParams = {fileType: "category"};
                editor.config.uploadImgMaxSize = 10 * 1024 * 1024;
                editor.config.onchange = (html) => {
                    this.content = html;
                    this.$emit("goods-content", this.content);
                };
                editor.config.uploadImgHeaders = {
                    Authorization: "Bearer " + localStorage.getItem('token') // 设置请求头
                }
                editor.config.uploadImgHooks = {
                    onchange() {
                    },
                    before() {
                        // 图片上传之前触发
                        // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件
                        // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
                        // return {
                        //     prevent: true,
                        //     msg: '放弃上传'
                        // }
                    },
                    success(xhr, editor, result) {
                        // 图片上传并返回结果，图片插入成功之后触发
                        // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
                        const imgUrl = Object.values(result.data).toString();
                        console.log(imgUrl);
                    },
                    fail() {
                        // 图片上传并返回结果，但图片插入错误时触发
                        // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
                        // console.log("fail");
                    },
                    error() {
                        // 图片上传出错时触发
                        // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
                        // console.log("error");
                    },
                    timeout() {
                        // 图片上传超时时触发
                        // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
                        // console.log("timeout");
                    },

                    // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
                    // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
                    customInsert(insertImg, res) {
                        // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
                        // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果

                        // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
                        if (res.code == 1) {
                            insertImg([`${this.$config.aliStaticUrl}${res.data.filePath}${res.data.fileName}`]);
                        }
                        // result 必须是一个 JSON 格式字符串！！！否则报错
                    },
                };
                editor.create();
                editor.txt.html(this.content);
            },
        },
    };
</script>
<style lang="scss" scoped>
</style>
